<template>
  <div>
    <nav class="sb-topnav navbar navbar-expand navbar-dark bg-white container-fluid py-0 pr-0">
      <div class="row no-gutters vw-100" :style="impersonateGetUserBgColor()">
        <div v-if="impersonating()" class="col-12 text-center h5 e-text-black font-weight-bold position-absolute mt-4">
          <b>{{ getUserRole(user) }}</b>
        </div>
        <div class="col-4">
          <!-- Sidebar Toggle-->
          <div class="row no-gutters w-100 d-flex align-items-center">
            <div class="col-auto pl-3 pr-1">
              <button class="btn btn-link btn-sm order-1 order-lg-0 px-3" id="sidebarToggle" href="#!"
                      @click="toggleSidebar">
                <font-awesome-icon icon="bars" class="e-text-red" style="transform: scaleX(2.5) scaleY(1.5)"/>
              </button>
            </div>
            <div class="col-6">
              <router-link :to="{name:'Dashboard'}">
                <img :src="systemLogo" alt="" style="height: 70px; width: auto">
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-8 d-flex align-items-center">
          <div class="row no-gutters w-100 justify-content-end align-items-center">
            <div class="col-auto mr-2">
              <button v-if="isElite()" class="btn btn-danger btn-sm" data-toggle="modal" data-target="#fleetContactListModal">
                <font-awesome-icon icon="file-pdf" class="force-white-all"></font-awesome-icon>
                MANAGE
              </button>
            </div>
            <div class="col-auto mr-2">
              <button class="btn btn-danger btn-sm" @click="viewContactList">
                <font-awesome-icon icon="file-pdf" class="force-white-all"></font-awesome-icon>
                FLEET CONTACT LIST
              </button>
            </div>
            <div class="col-auto">
              <select class="form-control form-control-sm" v-model="searchIn">
                <option v-for="searchModule in searchModules" :value="searchModule.id">{{searchModule.name}}</option>
              </select>
            </div>
            <div class="col-auto">
              <form @submit.prevent="find">
                <input type="text" id="searchString" class="form-control form-control-sm" style="width: 350px" placeholder="Search">
              </form>
            </div>
            <div class="row no-gutters align-items-center" v-if="isElite()">
              <div class="col-auto ml-2">
                <button class="btn btn-info btn-sm font-weight-bolder" data-toggle="modal" data-target="#modalImpersonateUserList">
                  <font-awesome-icon icon="users" class="force-white-all"></font-awesome-icon>
                  IMPERSONATOR
                </button>
              </div>
            </div>
            <div class="col-auto px-3 pr-0">
              <div class="dropdown">
                <button class="btn dropdown-toggle p-0 e-text-hover-red-light-3" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                  <font-awesome-icon icon="user" style="transform: scale(1.75)" class="e-text-red"/>
                </button>
                <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="dropdownMenuButton">
                  <p class="mb-1 pt-1 text-center text-capitalize">
                    {{ user.last_name }},
                    {{ user.first_name }}
                    {{ user.middle_name ? user.middle_name.charAt(0) : '' }}
                  </p>
                  <hr class="my-0"/>
                  <button class="dropdown-item text-center e-text-gray" type="button"
                          @click="$router.push({name: 'ChangePassword', params: {id: user.id}})">
                    <font-awesome-icon icon="pen"/>
                    Change Password
                  </button>
                  <hr class="my-0"/>
                  <button class="dropdown-item text-center e-text-red" type="button" @click="logout">
                    <font-awesome-icon icon="sign-out-alt"/>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="modalImpersonateUserList" role="dialog"
         aria-labelledby="modalImpersonateUserListLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">User Impersonation</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row align-item-center">
              <div class="col-12 pb-2 mr-2">
                <ul class="nav nav-tabs" id="modalImpersonateUserListTabs" role="tablist">
                  <template v-for="(roleData, roleKey, index) in usersByRole">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" type="button" role="tab" data-toggle="tab"
                              :id="'impersonate-' + roleKey + '-tab'"
                              :class="{'active' : (index == 0)}"
                              :aria-selected="(index == 0)"
                              :data-target="'#impersonate-' + roleKey + '-data'"
                              :aria-controls="roleKey">
                        {{ roleData.name }}
                      </button>
                    </li>
                  </template>
                </ul>
                <div class="tab-content overflow-auto" id="modalImpersonateUserListTabContent" style="max-height: 70vh;">
                  <template v-for="(roleData, roleKey, index) in usersByRole">
                    <div role="tabpanel" class="tab-pane fade pt-2"
                         :class="{'show active' : (index == 0)}"
                         :id="'impersonate-' + roleKey + '-data'"
                         :aria-labelledby="'impersonate-' + roleKey + '-tab'">
                      <table class="table table-bordered table-hover">
                        <thead :style="'background-color:' + getUserRole(roleKey, true)">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">USERNAME</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col">NAME</th>
                          <th scope="col" class="text-center">ACTION</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="!roleData.users.length">
                          <td colspan="4" class="text-center">No records found</td>
                        </tr>
                        <template v-else>
                          <tr v-for="user in roleData.users">
                            <th scope="row">{{ user.id }}</th>
                            <td>{{ user.username }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.name }}</td>
                            <td class="text-center text-info">
                              <button type="button" class="btn btn-xs btn-info force-white-all" @click="impersonateUser(user)">
                                <font-awesome-icon icon="sign-in-alt"/>
                                Impersonate
                              </button>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="modal fade" v-if="showAnnouncementModal" id="announcementModal" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Announcement for Updated Function</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="height: 70vh; overflow: auto; padding: 0">
            <!-- carousel -->
            <div
              id='carouselExampleIndicators'
              class='carousel slide'
              data-ride='carousel'
            >

<!--              <ol class="carousel-indicators">-->
<!--                <li-->
<!--                  v-for="(announcement, index) in activeAnnouncements"-->
<!--                  :key="index"-->
<!--                  :data-target="'#carouselExample'"-->
<!--                  :data-slide-to="index"-->
<!--                  :class="{ active: index === 0 }"-->
<!--                ></li>-->
<!--              </ol>-->
              <div class='carousel-inner' style="height: 70vh">

                <div
                  v-for="(announcement, index) in activeAnnouncements"
                  :key="index"
                  class="carousel-item"
                  :class="{ active: index === 0 }"
                  style="padding: 28px 100px 28px 100px; max-height: 70vh; overflow: auto"
                >
                  <h3>{{ announcement.title }}</h3>
                  <div v-html="announcement.body">
                  </div>
                  <div v-if="announcement.attachments.length > 0">
                    <h6>Attachments:</h6>

                    <div v-for="attachment in announcement.attachments">
                      <a :href="attachment.url | apiUrl" target="_blank">{{attachment.filename}}</a>
                    </div>
                  </div>
                </div>
              </div>


              <template v-if="activeAnnouncements.length > 0">
                <a
                  class='carousel-control-prev'
                  href='#carouselExampleIndicators'
                  role='button'
                  data-slide='prev'
                  style="background: rgb(0,0,0);
                  background: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 42%); width: 7%"
                >
              <span class='carousel-control-prev-icon'
                    aria-hidden='true'
              ></span>
                  <span class='sr-only'>Previous</span>
                </a>
                <a
                  class='carousel-control-next'
                  href='#carouselExampleIndicators'
                  role='button'
                  data-slide='next'
                  style="background: rgb(0,0,0);
                  background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 42%); width: 7%"
                >
              <span
                class='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
                  <span class='sr-only'>Next</span>
                </a>
              </template>

            </div>
          </div>
          <div class="modal-footer justify-content-lg-start">
<!--            <div class="form-check" style="display: flex; align-items: end">-->
<!--              <input @change="handleDontShow" v-model="showPopup" type="checkbox" class="form-check-input" id="exampleCheck1">-->
<!--              <label class="form-check-label" for="exampleCheck1">Don't show it again.</label>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" data-backdrop="static" id="fleetContactListModal" role="dialog" aria-labelledby="NewFleetContactListTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <form class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" style="margin: auto">MANAGE FLEET CONTACT LIST</h5>
          </div>
          <div class="modal-body">
            <input id = "fleet_input_file" type="file" ref="import_fleet_contact_list" accept=".pdf" @change.prevent="selectContactListFile" style="display:none;">
            <div style="float: right !important;">
              <div style="display: flex; justify-content: center; align-items: center;">
                <span id = "fleet_filename" class="px-1 mb-2"> {{ fleet_contact_list_name }}</span>
                <button class="e-btn e-btn-green e-btn-sm px-1 py-1 mb-2 mr-2 float-right" id = "uploadTest" @click.prevent="triggerImportFleet">SELECT FILE</button>
                <input type="date" min="1900-01-01" v-model="fleet_contact_list_date" class="form-control form-control-sm w-auto mb-2 px-1 py-1 mr-2">
                <button class="e-btn e-btn-blue force-white-all pr-3 mb-2 float-right" id = "uploadTest" @click.prevent="uploadContactList" :disabled="fleet_contact_list_name === '' || fleet_contact_list_date === ''" :class="fleet_contact_list_name === '' || fleet_contact_list_date === '' ? 'e-btn-blue-disabled' : ''">UPLOAD</button>
              </div>
            </div>
            <new-table>
              <template v-slot:table-header>
                <tr style="height: 46.28px">
                  <th class="align-middle text-center">File</th>
                  <th class="align-middle text-center">File Name</th>
                  <th class="align-middle text-center">File Date</th>
                  <th class="align-middle text-center">Date Uploaded</th>
                  <th class="align-middle text-center">Action</th>
                </tr>
              </template>
              <template v-slot:table-body>
                <template v-if="fleetContactLists.length > 0">
                  <tr style="height: 41.5px" v-for="list in fleetContactLists">
                    <td class="align-middle text-center">
                      <font-awesome-icon icon="file-pdf" class="force-danger-all" size="3x" style="cursor: pointer" @click.prevent="viewContactList(list.formatted_date, list.file_name)"></font-awesome-icon>
                    </td>
                    <td class="align-middle text-center">{{ list.file_name }}</td>
                    <td class="align-middle text-center">{{ list.formatted_date }}</td>
                    <td class="align-middle text-center">{{ list.file_created }}</td>
                    <td class="align-middle text-center">
                      <button @click.prevent = "deleteContactList(list.file_name)" class="delete-fleet-button dropdown-item text-secondary font-weight-bolder text-danger">
                        <font-awesome-icon icon="trash" class="force-danger-all"/>
                        Delete
                      </button></td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="text-uppercase" style="height: 41.5px">
                    <td class="align-middle text-center" colspan="100%">No Data</td>
                  </tr>
                </template>
              </template>
            </new-table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" @click.prevent="closeFleetUploadModal">Close</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {ReportService} from "@/services/ReportService";
import {permissionMixin} from "@/mixins/permissionMixins";
import NewTable from '../layouts/Table'
import {AlertService} from "@/services/AlertService";
import OtherComp from "@/components/elements/OtherComp.vue";
import {SERVER_BASE_URL} from "@/services/ServerConstant";
// import { WebSocketMixins } from '@/mixins/WebSocketMixins';

export default {
  name: 'TopHeader',
  components : {
    OtherComp,
    'new-table' : NewTable
  },
  data() {
    return {
      showPopup: false,
      systemLogo: require('@/assets/k-ids_logo.png'),
      dataSidebarToggle: true,
      searchIn:1,
      searchModules:[
        {
          id:1,
          name:'Accident/Incident'
        },
        {
          id:2,
          name:'PSC'
        },
      ],
      fleetContactLists : [],
      fleet_contact_list_date : '',
      fleet_contact_list_name : '',
    }
  },
  // mixins: [permissionMixin, WebSocketMixins],
  mixins: [permissionMixin],
  methods: {
    ...mapActions(['clearStorage', 'pullUserDetails', 'impersonateGetUsers', 'getActiveAnnouncement', 'getLatestAnnouncementTimestamp']),
    find() {
      var search = document.getElementById('searchString');
      if(this.searchIn === 1){
        this.$router.push({name: 'Module', query: {search: search.value, t: new Date().getTime()}});
      }
      else {
        this.$router.push({name: 'Psc', query: {search: search.value, t: new Date().getTime()}});
      }
    },
    toggleSidebar() {
      const sidebar = document.getElementById('layoutSidenav_nav')
      const content = document.getElementById('layoutSidenav_content')
      if (this.dataSidebarToggle === false) {
        sidebar.style.width = '195px'
        content.style.paddingLeft = '195px'
      } else {
        sidebar.style.width = '0'
        content.style.paddingLeft = '0'
      }
      this.dataSidebarToggle = !this.dataSidebarToggle
    },
    async deleteContactList(fileName) {
      if (await AlertService.confirmDeleteAlert('DELETION OF FLEET CONTACT LIST', 'Do you want to Delete this Fleet Contact Record?', 'Yes, Proceed!')) {
        let deleteRequest = await ReportService.deleteFleetContactList({
          file_name : fileName
        });
        if (deleteRequest.result === true) {
          this.fleetContactLists = await ReportService.getFleetContactLists();
        }
      }
    },
    triggerImportFleet() {
      $('#fleet_input_file').click();
    },
    closeFleetUploadModal() {
        $('#fleetContactListModal').modal('hide');
    },
    async uploadContactList() {
      let formData = new FormData();
      let file = this.$refs.import_fleet_contact_list.files[0];
      formData.append('file', file);
      formData.append('file_date', this.fleet_contact_list_date);
      const uploadResult = await ReportService.uploadContactList(formData);
      if (uploadResult.result === true) {
        AlertService.successAlert(`New Fleet Contact List has been created`, 'UPLOADING FLEET CONTACT LIST');
        this.fleetContactLists.unshift({
          file_date      : uploadResult.file_date,
          file_name      : uploadResult.file_name,
          file_created   : uploadResult.file_created,
          formatted_date : uploadResult.formatted_date,
        });
        this.fleet_contact_list_name = '';
        this.fleet_contact_list_date = '';
        $('#fleet_input_file').val(this.fleet_contact_list_name);
      }
    },
    async selectContactListFile(event) {
      let file = event.target.files[0];
      if (!file) return;

      if (file.type !== 'application/pdf') {
        alert('Please upload a PDF file.');
        this.fleet_contact_list_name = '';
        $('#fleet_input_file').val('');
        return;
      }
      this.fleet_contact_list_name = file.name;
    },
    async viewContactList(formattedDate, fileName) {
      swal.fire(({
        'title': 'Generating Contact List',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      }));
      swal.showLoading()

      let getContactDateRequest = {
        result         : true,
        formatted_date : formattedDate
      };
      let params = {
        file_name : fileName
      };

      if (typeof formattedDate !== 'string') {
        getContactDateRequest = await ReportService.getContactListCreatedDate();
        params = {};
      }

      if (getContactDateRequest.result === true) {
        let fleetContactFile = await ReportService.getContactList(params);
        swal.close();
        fleetContactFile = window.URL.createObjectURL(new Blob([fleetContactFile], {type: 'application/pdf'}))
        swal.fire({
          title: `FLEET CONTACT LIST <small style="font-size: 15px;" class="text-danger font-weight-bold">(Updated as of ` + getContactDateRequest.formatted_date + `)<small>`,
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          width: '1280px',
          confirmButtonColor: '#056cb4',
          'html': `<iframe src="${fleetContactFile}"   style="width: 100%; height: 70vh;" frameborder="0" />`
        })
      } else {
        swal.close();
      }
    },
    handleDontShow(){
      if(this.showPopup) {
        localStorage.setItem("sap", new Date().getTime().toString());
      }

      $('#announcementModal').modal('hide')
    }
  },
  filters: {
    apiUrl: function (value) {
      return SERVER_BASE_URL + value
    }
  },
  async created() {
    this.pullUserDetails();
    if (this.isElite()) {
      await this.impersonateGetUsers();
      this.fleetContactLists = await ReportService.getFleetContactLists();
    }
    await this.getActiveAnnouncement()
    await this.getLatestAnnouncementTimestamp()

    if (this.activeAnnouncements.length > 0) {
      $('#announcementModal').modal('show')
    }

  },
  mounted() {
    if(this.$router.currentRoute.matched[1].name.toLowerCase() === 'psc'){
      this.searchIn = 2
    }

    const bchannel = this.wsChannel('channel')
      .listen('CompanyInstructionSent', (payload) => {
        console.log(payload);
      })
    ;

    // console.log(bchannel);

  },
  computed: {
    ...mapGetters(['user', 'usersByRole', 'activeAnnouncements', 'latestAnnouncementTimestamp', "showAnnouncementModal"]),
    shouldShowPopup(){
      const timestamp = localStorage.getItem("sap")

      if(timestamp) {
        return this.latestAnnouncementTimestamp > timestamp
      }

      return true
    }
  },
  watch: {
    $route(to, from) {
      if (!['ReportIndex','PscIndex'].includes(to.name)) {
        var search = document.getElementById('searchString');
        search.value = '';
      }
    },
  }
}
</script>

<style scoped>
.dropdown-toggle::after {
  content: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none !important;
}


</style>
